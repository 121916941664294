/* Import Google Font */
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&display=swap');

/* Reset and Base Styles */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    font-family: 'Inter', sans-serif;
    line-height: 1.6;
    color: #333;
    background-color: #fff;
}

.container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 1.5rem;
}

/* Navbar */
.navbar {
    position: fixed;
    width: 100%;
    z-index: 1000;
    transition: all 0.3s ease;
}

.navbar.scrolled {
    background: rgba(255, 255, 255, 0.9);
    backdrop-filter: blur(10px);
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.nav-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 1.5rem;
    max-width: 1200px;
    margin: 0 auto;
}

.logo {
    font-size: 1.5rem;
    font-weight: 300;
    letter-spacing: -0.5px;
}

.logo span {
    font-weight: 700;
}

.nav-links {
    display: flex;
    gap: 2rem;
}

.nav-link {
    color: #666;
    text-decoration: none;
    transition: color 0.3s ease;
}

.nav-link:hover {
    color: #8c9e02;
}

/* Hamburger Menu (Mobile) */
.hamburger {
    display: none;
    /* Hidden by default; shown via media query */
    width: 30px;
    height: 2px;
    background: #333;
    position: relative;
    cursor: pointer;
    transition: all 0.3s ease;
}

.hamburger::before,
.hamburger::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: inherit;
    transition: inherit;
}

.hamburger::before {
    top: -8px;
}

.hamburger::after {
    top: 8px;
}

.hamburger.open {
    background: transparent;
}

.hamburger.open::before {
    transform: rotate(45deg);
    top: 0;
}

.hamburger.open::after {
    transform: rotate(-45deg);
    top: 0;
}

/* Hero Section */
.hero-section {
    padding: 8rem 0 5rem;
    position: relative;
}

.hero-content {
    max-width: 800px;
}

.hero-title {
    font-size: 3.5rem;
    font-weight: 300;
    line-height: 1.2;
    margin-bottom: 1.5rem;
}

.hero-title span {
    display: block;
    font-weight: 600;
}

.hero-description {
    font-size: 1.25rem;
    color: #666;
    margin-bottom: 2rem;
}

.hero-buttons {
    display: flex;
    gap: 1rem;
}

.hero-scroll-indicator {
    position: absolute;
    bottom: 2rem;
    left: 50%;
    transform: translateX(-50%);
}

/* Buttons */
.primary-button {
    background: #d2c104;
    color: white;
    padding: 0.75rem 1.5rem;
    border: none;
    border-radius: 0.5rem;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.primary-button:hover {
    background: #8c9e02;
}

.secondary-button {
    background: white;
    color: #333;
    padding: 0.75rem 1.5rem;
    border: 1px solid #ddd;
    border-radius: 0.5rem;
    font-size: 1rem;
    cursor: pointer;
    transition: all 0.3s ease;
}

.secondary-button:hover {
    border-color: #8c9e02;
    color: #8c9e02;
}

/* Services Section */
.services-section {
    padding: 5rem 0;
    background: #f8f9fa;
}

.section-header {
    text-align: center;
    max-width: 600px;
    margin: 0 auto 4rem;
}

.section-title {
    font-size: 2.5rem;
    font-weight: 300;
    margin-bottom: 1rem;
}

.section-description {
    color: #666;
}

.main-services {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 2rem;
    margin-bottom: 4rem;
}

.service-card {
    background: white;
    padding: 2rem;
    border-radius: 1rem;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.05);
    transition: transform 0.3s ease, box-shadow 0.3s ease, opacity 0.6s cubic-bezier(0.4, 0, 0.2, 1);
    opacity: 0;
    transform: translateY(30px);
}

.service-card.visible {
    opacity: 1;
    transform: translateY(0);
}

.service-icon {
    font-size: 2rem;
    margin-bottom: 1.5rem;
}

.service-icon .icon-wrapper {
    animation: float 3s ease-in-out infinite;
}

.service-title {
    font-size: 1.5rem;
    font-weight: 500;
    margin-bottom: 1rem;
}

.service-description {
    color: #666;
    margin-bottom: 1.5rem;
}

.feature-list {
    list-style: none;
}

.feature-item {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    color: #666;
    margin-bottom: 0.5rem;
    font-size: 0.95rem;
    opacity: 0;
    transform: translateX(-20px);
    transition: all 0.3s ease;
}

.service-card.visible .feature-item {
    opacity: 1;
    transform: translateX(0);
}

.arrow-icon {
    color: #8c9e02;
}

/* Showcase Section */
.showcase-section {
    padding: 5rem 0;
}

.showcase-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 4rem;
    align-items: center;
}

.showcase-title {
    font-size: 2.5rem;
    font-weight: 300;
    margin-bottom: 1rem;
}

.showcase-description {
    color: #666;
    margin-bottom: 2rem;
}

.tech-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
}

.tech-item {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    color: #666;
    transition: all 0.3s ease;
    cursor: default;
}

.tech-item:hover {
    transform: scale(1.05);
    color: #8c9e02;
}

.stats-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
}

.energy-ball-container {
    width: 100%;
    height: 400px;
    position: relative;
}

.stat-card {
    background: white;
    padding: 1.5rem;
    border-radius: 0.5rem;
    box-shadow: 0 5px 20px rgba(0, 0, 0, 0.05);
    transition: transform 0.3s ease;
}

.stat-card:hover {
    transform: translateY(-5px);
}

.stat-value {
    font-size: 2rem;
    font-weight: 300;
    color: #8c9e02;
    margin-bottom: 0.1rem;
}

/* Contact Section */
.contact-section {
    padding: 5rem 0;
    background: #f8f9fa;
}

.contact-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 4rem;
    align-items: start;
}

.contact-info {
    padding-right: 2rem;
}

.contact-title {
    font-size: 2.5rem;
    font-weight: 300;
    margin-bottom: 1rem;
}

.contact-description {
    color: #666;
    margin-bottom: 2rem;
}

.contact-details {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.contact-link {
    color: #5151fd;
    text-decoration: none;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    transition: color 0.3s ease;
}

.contact-link:hover {
    color: #7070ef;
}

.contact-address {
    color: #666;
    display: flex;
    align-items: start;
    gap: 0.5rem;
}

/* Contact Form */
.contact-form {
    background: white;
    padding: 2rem;
    border-radius: 1rem;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.05);
}

.form-group {
    margin-bottom: 1.5rem;
}

.form-group label {
    display: block;
    color: #666;
    margin-bottom: 0.5rem;
    font-size: 0.95rem;
}

.form-group input,
.form-group textarea {
    width: 100%;
    padding: 0.75rem;
    border: 1px solid #ddd;
    border-radius: 0.5rem;
    font-family: inherit;
    font-size: 1rem;
    transition: all 0.3s ease;
}

.form-group input:focus,
.form-group textarea:focus {
    outline: none;
    border-color: #8c9e02;
    box-shadow: 0 0 0 3px rgba(26, 115, 232, 0.1);
}

.form-group textarea {
    resize: vertical;
    min-height: 120px;
}

/* Footer */
.footer {
    padding: 2rem 0;
    background: #f8f9fa;
    text-align: center;
    color: #666;
    border-top: 1px solid #eee;
}

/* Animations */
@keyframes float {

    0%,
    100% {
        transform: translateY(0);
    }

    50% {
        transform: translateY(-10px);
    }
}

@keyframes textReveal {
    from {
        clip-path: inset(0 100% 0 0);
    }

    to {
        clip-path: inset(0 0 0 0);
    }
}

@keyframes scaleIn {
    from {
        transform: scale(0);
        opacity: 0;
    }

    to {
        transform: scale(1);
        opacity: 1;
    }
}

@keyframes scroll {
    0% {
        transform: translateY(0);
        opacity: 0;
    }

    10% {
        opacity: 1;
    }

    100% {
        transform: translateY(15px);
        opacity: 0;
    }
}

/* Responsive Design */
@media (max-width: 768px) {

    /* Navbar: Hide default nav links and show hamburger */
    .nav-links {
        display: none;
        /* Hidden by default on mobile */
        flex-direction: column;
        background: rgba(255, 255, 255, 0.95);
        position: absolute;
        top: 100%;
        right: 0;
        width: 200px;
        padding: 1rem;
        box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    }

    .nav-links.show {
        display: flex;
        animation: slideDown 0.4s ease-out;
    }

    .hamburger {
        display: block;
    }

    /* Hero Section adjustments */
    .hero-title {
        font-size: 2.5rem;
    }

    .hero-description {
        font-size: 1rem;
    }

    .hero-buttons {
        flex-direction: column;
    }

    .primary-button,
    .secondary-button {
        width: 100%;
        text-align: center;
    }

    .hero-section {
        padding: 4rem 0 3rem;
    }

    /* Grid adjustments for single-column layouts */
    .contact-grid,
    .showcase-grid {
        grid-template-columns: 1fr;
        gap: 2rem;
    }

    .stats-grid {
        grid-template-columns: repeat(2, 1fr);
    }

    /* SlideDown Animation for Mobile Nav */
    @keyframes slideDown {
        from {
            transform: translateY(-100%);
            opacity: 0;
        }

        to {
            transform: translateY(0);
            opacity: 1;
        }
    }
}

@media (max-width: 480px) {
    .hero-title {
        font-size: 2rem;
    }

    .hero-description {
        font-size: 0.9rem;
    }

    .nav-links {
        width: 100%;
        right: 0;
    }
}

/* --- Navbar and Hamburger --- */
.navbar {
    position: fixed;
    width: 100%;
    z-index: 1000;
    transition: all 0.3s ease;
}

.navbar.scrolled {
    background: rgba(255, 255, 255, 0.9);
    backdrop-filter: blur(10px);
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.nav-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 1.5rem;
    max-width: 1200px;
    margin: 0 auto;
}

.nav-links {
    display: flex;
    gap: 2rem;
}

/* Hide nav links on mobile; show when .show is added */
@media (max-width: 768px) {
    .nav-links {
        display: none;
        flex-direction: column;
        background: rgba(255, 255, 255, 0.95);
        position: absolute;
        top: 100%;
        right: 0;
        width: 200px;
        padding: 1rem;
        box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    }

    .nav-links.show {
        display: flex;
        animation: slideDown 0.4s ease-out;
    }
}

/* Hamburger Menu */
.hamburger {
    display: none;
    width: 30px;
    height: 2px;
    background: #333;
    position: relative;
    cursor: pointer;
    transition: all 0.3s ease;
}

.hamburger::before,
.hamburger::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: inherit;
    transition: inherit;
}

.hamburger::before {
    top: -8px;
}

.hamburger::after {
    top: 8px;
}

.hamburger.open {
    background: transparent;
}

.hamburger.open::before {
    transform: rotate(45deg);
    top: 0;
}

.hamburger.open::after {
    transform: rotate(-45deg);
    top: 0;
}

/* Ensure the hamburger shows on mobile */
@media (max-width: 768px) {
    .hamburger {
        display: block;
    }
}

/* --- Hero Section (for example) --- */
.hero-section {
    padding: 8rem 0 5rem;
    position: relative;
}

/* Adjust button styling if needed */
.primary-button,
.secondary-button {
    text-decoration: none;
    display: inline-block;
}